import api from "@/api"
import { DefaultSetting } from "@/models/type"
import { parseTime, sharer } from "@/utils"
import { Vue } from "vue-class-component"
import { RouterView } from "vue-router"
import './index.scss'

export default class ActivityShow extends Vue {
  pageSize = 4
  pageNum = 1
  total = 0

  public showList: Array<SmsShow> = []

  getList(): void {
    api.getShowAllList({
      pageSize: this.pageSize,
      pageNum: this.pageNum
    }).then(res => {
      this.total = res.data.total
      this.pageNum = res.data.pageNum
      this.pageSize = res.data.pageSize
      this.showList = res.data.list
    })
  }

  listShow(): JSX.Element {
    return <div class="activity_show-list">{(
      this.showList.map(item => {
        return <div class="list-cell">
          <img src={item.coverImg} alt={item.title} />
          <div class="list-cell-card">
            <div class="list-cell-card-content">
              <span class="list-cell-card-time">{parseTime(new Date(item.signUpStartTime as string).getTime(), '{y}.{m}.{d}') || '19.2.2'}</span>
              <div>
                <div class="list-cell-card-title">{item.title}</div>
                <div class="list-cell-card-position"><i class="el-icon-location-outline"/><span>{item.position}</span></div>
              </div>
              <p class="list-cell-card-introduce">{item.introduce}</p>
              <div class="list-cell-card-bottom">
                <div class="list-cell-card-fun">
                  <div class="facebook" onClick={() => sharer(1)} />
                  <div class="wechat" onClick={() => sharer(2)} />
                  <div class="phone" onClick={() => sharer(3)} />
                </div>
                <div class="list-cell-card-submit" onClick={() => this.goDetail(item.id as number)}>查看详情</div>
              </div>
            </div>
          </div>
        </div>
      })
    )}</div>
  }

  /**
   * @description 去详情
   * @author Jinx
   * @date 2021-10-18 10:20:11
   * @param {number} id
   * @memberof ActivityShow
   */
  goDetail(id: number): void {
    this.$router.push({
      name: 'ActivityShowDetail',
      params: {id}
    })
  }

  handleCurrentChange(pageNum: number): void {
    this.pageNum = pageNum
    this.getList()
  }

  render(): JSX.Element {
    return this.$route.path === '/activity/show'
      ? <div class="activity_show">
        {this.listShow()}
        <el-pagination
          class="activity_show-pagination"
          onCurrentChange={this.handleCurrentChange}
          currentPage={this.pageNum}
          pageSize={this.pageSize}
          layout="prev, pager, next, total"
          total={ this.total }
        />
      </div>
      : <RouterView />
  }
  mounted(): void {
    this.getList()
  }
}


/**
 * @description 展览
 * @author Jinx
 * @date 2021-08-25 13:51:47
 * @export
 * @interface SmsShow
 * @extends {DefaultSetting}
 */
 export interface SmsShow extends DefaultSetting {
  articleImg?: string // 艺术家照片
  articleIntroduce?: string // 艺术家个人介绍
  articleId?: number // 艺术家名称
  coverImg?: string // 封面图片
  introduce?: string // 展览介绍
  mainImg?: string // 主图
  otherImg?: string // 其他图片
  position?: string // 展览地点
  signUpEndTime?: string | Date // 报名结束时间
  signUpStartTime?: string | Date // 报名开始时间
  signUpTime?: Array<Date> // 前端时间
  title?: string // 展览名称
}
